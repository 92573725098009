<template>
  <v-container class="down-top-padding" fluid>
    <base-breadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"></base-breadcrumb>
    <base-card :button="button" :heading="page.title">
      <v-row class="ma-3 justify-center align-center">
        <v-col class="d-flex justify-end align-end" cols="12">
        <div class="md:tw-block tw-hidden ">
          <v-btn-toggle v-model="search.typeExam" mandatory @change="setTypeSearch()">
            <v-btn v-for="i in types" :key="i.value" :disabled="disabled" :value="i.value" outlined style="height: 56px">
              {{ i.name }}
            </v-btn>
          </v-btn-toggle>
        </div>
          <div class="tw-block md:tw-hidden tw-w-full">
            <v-select
              v-model="search.typeExam"
              :items="types"
              background-color="transparent"
              item-text="name"
              item-value="id"
              label="Tipo"
              outlined
              @change="setTypeSearch()"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <datatable-list
        v-if="examFetch"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        :disableSort="true"
        :emit="search.typeExam === 1"
        :showSelect="showSelect"
        :disabledDescription="disabledDescription"
        :displayActionItems="false"
        :loading="!examFetch"
        :pagination="pagination"
        :text="text"
        @actionButton="actionButton($event)"
        @openModal="openModal($event)"
        @update="pagination = { ...pagination, ...$event}"
        @select-item="openExamPreview($event)"
      ></datatable-list>
      <template v-else>
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="table-heading"
        ></v-skeleton-loader>
      </template>
    </base-card>
    <dialog-delete
      :dialog="dialogDelete"
      header-message="Deseja excluir esse modelo de avaliação?"
      body-message="Essa ação não pode ser desfeita depois da confirmação."
      deny-message="Cancelar"
      confirm-message="Confirmar exclusão"
      :delete-endpoint="search.typeExam === 0
        ? `organizations/${id}/exams/${deleteId}`
        : `organizations/${id}/exams-database/${deleteId}`"
      @deleteSuccess="deleteSuccess()"
      @closeModal="closeModal()" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    dialogDelete: false,
    deleteId: '',
    searchQuery: '',
    enunciated: 'teste',
    examPreview: false,
    examFetch: false,
    permissions: {},
    id: '',
    types: [
      { name: 'Minhas Avaliações', id: 0 },
      { name: 'Modelos da Instituição', id: 1 }
    ],
    search: {
      typeExam: 0
    },
    page: {
      title: 'Avaliações'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Avaliações',
        disabled: false,
        to: '/exam/list'
      }
    ],
    button: {
      show: false,
      url: '/exam/add',
      text: 'Avaliação',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Avaliações',
      emptyLabel: 'Nenhuma Avaliação encontrada',
      addLabel: 'Adicionar Avaliação',
      addUrl: '/examdatabase/add',
      importLabel: 'Importar Avaliação',
      importUrl: '/examdatabase/add'
    },
    disabledDescription: 'Avaliação já aplicada.',
    headers: [
      {
        text: 'Avaliação',
        align: 'start',
        value: 'title',
        sortable: true
      },
      { text: 'Descrição', value: 'subtitle', align: ' d-none', sortable: false },
      { text: 'Ações', value: 'actionButton', align: 'start', sortable: false }
    ],
    sortBy: '',
    showSelect: false,
    items: [],
    pagination: {
      page: 1,
      prev_page_url: false,
      next_page_url: false
    },
    disabled: false
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getExams()
      }
    }
  },
  methods: {
    ...mapActions('exams', ['getExam']),
    ...mapMutations('exams', ['SET_EXAM_SELECTED']),
    ...mapMutations(['setLoading']),
    openModal (item) {
      this.dialogDelete = true
      this.deleteId = item.path
    },
    setTypeSearch () {
      this.disabled = true
      this.getExams()
    },
    actionButton (item) {
      if (this.search.typeExam === 1) {
        this.copyExam(item.path)
        this.search.typeExam = 0 // change to section "Minhas Avaliações"
        this.getExams()
      } else {
        this.$router.push({ path: `/exam/application/${item.path}` })
      }
    },
    changePagination (page) {
      this.pagination.current_page += page
      this.getExams()
    },
    formatExams (questions) {
      const arr = []
      if (!questions.length) { return arr }
      questions.forEach((value, index) => {
        const obj = {
          ...value.data,
          checked: false,
          title: value.data.name,
          url: this.search.typeExam === 0 ? `view/${value.metadata.id}` : `/exam-database/${value.metadata.id}`,
          actionButton: {
            icon: this.search.typeExam === 0 ? 'mdi-text-box-plus-outline' : 'mdi-text-box-multiple-outline',
            text: this.search.typeExam === 0 ? 'Aplicar Avaliação' : 'Adicionar as minhas Avaliações',
            path: value.metadata.id
          },
          deleteButton: {
            icon: 'mdi-delete-outline',
            text: 'Excluir Avaliação',
            path: value.metadata.id,
            author: value.data.author.id
          },
          id: value.metadata.id,
          time: value.metadata.updateTime
        }
        arr.push(obj)
      })
      return arr
    },
    getExams () {
      this.examFetch = false
      const pagination = `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`
      let path
      if (this.search.typeExam === 1) {
        path = `/organizations/${this.id}/exams-database${pagination}`
      }
      if (this.search.typeExam === 0) {
        path = `/organizations/${this.id}/exams${pagination}`
      }
      this.$axios.get(path, {
        order_by: [
          {
            column: 'id',
            direction: 'DESC'
          }
        ]
      }).then(response => {
        const pagination = response.data.pagination
        this.pagination = {
          ...this.pagination,
          total: pagination.total,
          page: pagination.current_page,
          itemsPerPage: pagination.per_page
        }
        this.items = this.formatExams(response.data.items)
        this.button.show = true
      }).catch(error => {
        if (error.response) {
          Object.values(error.response.data.errors).map((value) => {
            return this.$store.dispatch('alert', {
              color: 'red',
              msg: value[Object.keys(value)]
            })
          })
        }
      }).finally(() => {
        this.examFetch = true
        this.disabled = false
      })
    },
    copyExam (id) {
      this.setLoading(true)
      const self = this
      this.$axios
        .post(`/organizations/${this.id}/exams/import`, { exam: { id } })
        .then(response => {
          self.search.typeExam = 0
          self.$store.dispatch('alert', {
            msg: 'Avaliação copiada com sucesso.'
          })
        })
        .catch(error => {
          if (error.response) {
            Object.values(error.response.data.errors).map((value) => {
              return this.$store.dispatch('alert', {
                color: 'red',
                msg: value[Object.keys(value)]
              })
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    deleteSuccess () {
      this.dialogDelete = false
      this.getExams()
      this.$store.dispatch('alert', {
        msg: 'Modelo de avaliação excluído com sucesso.'
      })
    },
    closeModal () {
      this.dialogDelete = false
    }
  },
  computed: {
    ...mapState(['user']),
    level () {
      return this.user.level
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.disabled = true
    this.getExams()
  }
}
</script>
